header {
    background-color: #222222;
    padding: 20px 10px;
}

.header-container {
    max-width: 1100px;
    margin: 0 auto;
}

.com-logo img {
    width: 200px;
}

.accordion {
    max-width: 1100px;
    margin: 2rem auto;
}

.teamNameMain {
    display: flex;
    background-color: #222222;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    text-align: left;
}
.teamName {
    flex-grow: 1;
}
.teamMain.active .teamNameMain {
    background-color: #edb75f;
}
.teamMain {
    margin-bottom: 15px;
}

.teamDataMain {
    display: flex;
    flex-wrap: wrap;
    padding: 25px 15px;
    display: none;
}
.teamDataMain.active {
    display: flex;
}
.teamDataMain-inner {
    flex: 0 0 25%;
    padding: 8px 8px;
}
.teamData {
    border: 1px solid #d7d7d7;
    padding: 20px 10px;
}
.teamMemberName {
    margin-bottom: 5px;
    font-weight: bold;
}

.teamData.Online {
    border-left: 5px solid #22c60c;
}
.teamData.Offline {
    border-left: 5px solid #d7d7d7;
}

.custom-search-inner {
    position: relative;
}

.search-icon {
    position: absolute;
    top: 6px;
    left: 11px;
}
.search-icon img {
    width: 20px;
}

.custom-search-inner input {
    border: 0;
    border-bottom: 2px solid #edb75f;
    border-radius: 0;
    padding-left: 32px;
    padding-bottom: 11px;
}

.custom-search-inner input:focus {
    outline: none;
    box-shadow: none;
    border-color: #222222;
}

.teamMain.activeMain .teamDataMain {
    display: flex;
}

.teamMain.active .teamNameMain .teamIcon-plus {
    display: none;
}

.teamMain .teamNameMain .teamIcon-minus {
    display: none;
}


.teamMain.active .teamNameMain .teamIcon-minus {
    display: block;
}

@media only screen and (max-width:768px) {
    .teamDataMain-inner {
        flex: 0 0 33.333%;
    }
}
@media only screen and (max-width:480px) {
    .teamDataMain-inner {
        flex: 0 0 50%;
    }
}

@media only screen and (max-width:380px) {
    .teamDataMain-inner {
        flex: 0 0 100%;
    }
}